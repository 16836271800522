
import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"

function UtilityLayout({ children }) {
  return (
    <>
      <Helmet
        htmlAttributes={{
          class: 'scroll-smooth'
        }}
        bodyAttributes={{
          class: 'overflow-x-hidden font-sans text-base bg-white text-neutral-400'
        }}
        link={[
          {
            rel: "preconnect",
            href: "https://fonts.googleapis.com"
          },
          {
            rel: "preconnect",
            href: "https://fonts.gstatic.com",
            crossOrigin: ""
          },
          {
            href: "https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,300;0,400;0,500;0,700;1,400&display=swap",
            rel: "stylesheet"
          }
        ]}
        script={[
          {
            // TODO: parametrize host/url in course layout
            dataDomain: "extremeautomation.io",
            src: "https://plausible.io/js/script.outbound-links.file-downloads.js",
            defer: true
          },
        ]} >
      </Helmet>
      <main id="main-content" className="container mt-2 md:mt-11">{children}</main>
    </>
  )
}

UtilityLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default UtilityLayout
